<template>

  <div>

    <customer-list-add-new
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      @refetch-data="fetCustomers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-card-body>

        <div class="d-flex align-items-center justify-content-end">
          <b-form-group
            class="mb-0 mr-1"
          >
            <b-input-group size="lg">
              <b-form-input
                v-model="searchQuery"
                type="search"
                :disabled="isBusy"
                placeholder="Type to Search"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            class="mb-0 mr-1"
          >
            <b-button
              variant="primary"
              size="lg"
              :disabled="isBusy"
              @click="isAddNewCustomerSidebarActive = true"
            >
              <span class="text-nowrap">Add Customer</span>
            </b-button>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="customers"
        responsive
        :fields="tableColumns"
        primary-key="customerID"
        show-empty
        empty-text="No matching records found"
        :no-border-collapse="true"
        sticky-header="80vh"
        :busy="isBusy"
        :filter="searchQuery"
        :filter-included-fields="filterOn"
      >

        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                variant="light-primary"
                :to="{ name: 'apps-customers-edit', params: { id: data.item.customerID } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-customers-edit', params: { id: data.item.customerID } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.customerID }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-customers-edit', params: { id: data.item.customerID } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteThisCustomer(data.item.customerID)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BSpinner, BCardBody, BInputGroup, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import customer from '@/store/customer'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerListAddNew from './CustomerListAddNew.vue'
// eslint-disable-next-line import/no-cycle

export default {
  components: {

    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormGroup,
    CustomerListAddNew,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-customers'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, customer)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewCustomerSidebarActive = ref(false)
    const customers = ref([])
    const searchQuery = ref('')
    const isBusy = ref(false)
    const filterOn = ['name']
    const toast = useToast()

    const fetCustomers = () => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchAllCustomers`)
        .then(response => {
          isBusy.value = false
          customers.value = response.data
        })
        .catch(error => {
          console.log(error)
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching customers list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const deleteThisCustomer = id => {
      isBusy.value = true
      store
        .dispatch(`${USER_APP_STORE_MODULE_NAME}/deleteCustomer`, id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          fetCustomers()
        })
        .catch(() => {
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting Customer',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const tableColumns = [
      {
        key: 'name', sortable: true, filterable: true, filter: 'string',
      },
      { key: 'actions' },
    ]

    fetCustomers()

    return {

      // Sidebar
      isAddNewCustomerSidebarActive,
      fetCustomers,
      tableColumns,
      searchQuery,
      isBusy,
      avatarText,
      customers,
      filterOn,
      deleteThisCustomer,
    }
  },
}
</script>
